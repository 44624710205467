<template>
  <div>
    <a-modal v-model="visible" :confirmLoading="loading" :maskClosable="false" centered @cancel="cancel" @ok="create">
      <div slot="title">新增移库</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
          <a-form-model-item
            prop="location_out"
            label="源库位"
            @change="
              () => {
                form.material = undefined;
                form.batch_out = undefined;
              }
            "
          >
            <location-search-select v-model="form.location_out" :warehouse="currentWarehouse" />
          </a-form-model-item>
          <a-form-model-item prop="material" label="产品" @change="form.batch_out = undefined">
            <material-select v-model="form.material" />
          </a-form-model-item>
          <a-form-model-item prop="batch_out" label="批次">
            <batch-search-select v-model="form.batch_out" :location="form.location_out" :material="form.material" />
          </a-form-model-item>
          <a-form-model-item prop="transfer_quantity" label="移库数量">
            <a-input-number v-model="form.transfer_quantity" style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item prop="location_out" label="目标库位">
            <location-search-select v-model="form.location_in" :warehouse="currentWarehouse" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { locationTransferRecordCreate } from "@/api/stockTransfer";
import { rules } from "./rules";

export default {
  components: {
    LocationSearchSelect: () => import("@/components/LocationSearchSelect/"),
    BatchSearchSelect: () => import("@/components/BatchSearchSelect/"),
    MaterialSelect: () => import("@/components/MaterialSelect/"),
  },
  props: ["visible"],
  model: { prop: "visible", event: "cancel" },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  data() {
    return {
      rules,
      loading: false,
      form: {},
    };
  },
  methods: {
    create() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          locationTransferRecordCreate(this.form)
            .then((data) => {
              this.$message.success("新增成功");
              this.$parent.items = this.$functions.insertItem(this.$parent.items, data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
    selectMaterial(item) {
      this.form.material = item.material;
      this.form.material_name = item.material_item.name;
    },
    resetForm() {
      this.form = {};
    },
  },
  watch: {
    visible(status) {
      if (status) {
        this.resetForm();
      }
    },
  },
};
</script>

<style scoped></style>
